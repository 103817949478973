import _ from 'lodash';

import { ENDPOINTS } from './api';
import { store } from '..';
import { reducer } from '../actions';
import { InitMetric } from '../modules/others/metrics'

const geocodeHintUrl = (value) => {
    const location = store.getState()[reducer.name].currentLocation;

    return `${ENDPOINTS.geocodeHint}?q=${value}&lat=${location[0]}&lon=${location[1]}`;
};

const queries = {
  CURRENT_USER: () => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: 'CURRENT_USER',
      })
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to authorize user'));
    })
    .then((result) => {
      if (result.data.result.metrics) {
        localStorage.setItem('appUserHistory', JSON.stringify(result.data.result.metrics));
        return Promise.resolve(result.data.result);
      } else if (!result.data.result.metrics) {
        localStorage.setItem('appUserHistory', JSON.stringify(InitMetric));
        return Promise.resolve(result.data.result);
      }

      return Promise.reject(new Error('Failed to authorize user'));
    }),

  UPDATE_USER: (metric) => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: 'UPDATE_USER',
        variables: {
          data: {
            metrics: metric,
          }
        }
      })
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to authorize user'));
    })
    .then((result) => {
      if (result.data.result.metrics) {
        localStorage.setItem('appUserHistory', JSON.stringify(result.data.result.metrics));
        return Promise.resolve(result.data.result);
      }
      return Promise.reject(new Error('Failed to authorize user'));
    })
    .catch((error) => Promise.reject(new Error(error.message))),

  UPDATE_USER_PASSWD: (passwd, id) => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: 'UPDATE_USER',
        variables: {
          id: id,
          data: {
            password: passwd,
          }
        }
      })
    })
    .then((response) => {
      if (response.ok) {
        // console.log('1result Change Passwd', response);
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to authorize user'));
    })
    .then((result) => {
      if (result) {
          // console.log('2result Change Passwd', result);
        return Promise.resolve(result.data.result);
      }
      return Promise.reject(new Error('Failed change password'));
    })
    .catch((error) => console.log('Error UPDATE_USER_PASSWD', error)),

  OTP_EMAIL_KEY: (otp) => fetch(ENDPOINTS.login, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        otp: otp,
      })
    })
    .then((res) => {
      if (!res)
        return Promise.reject(new Error('Error OTP key'));
      return res.json();
    }),

  OPTIMIZATION_START: (manifest, direct) => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${direct
          ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODM4NzY1MzQsInVzZXJfaWQiOjI2ODIyLCJubyI6MH0.2TGnGfGLmzS-P0ZK-dgwZi-PGdp6dGFCTPXEhzvFkhc'
          : store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: direct ? 'OPTIMIZATION_START' : 'NEW_OPTIMIZATION_START',
        variables: direct
        ? {
          manifest: manifest.manifestDef,
          direct: true
        }
        : {
          manifest_id: manifest,
        }
      })
    })
    .then((response) => response && response.json()),

  DIRECT_OPTIMIZATION_RESULT: (manifestId) => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODM4NzY1MzQsInVzZXJfaWQiOjI2ODIyLCJubyI6MH0.2TGnGfGLmzS-P0ZK-dgwZi-PGdp6dGFCTPXEhzvFkhc`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: 'DIRECT_OPTIMIZATION_RESULT',
        variables: {
          manifest_id: manifestId
        }
      })
    })
    .then((response) => response && response.json()),

  OPTIMIZATION_STATUS: (manifestId) => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: 'NEW_OPTIMIZATION_STATUS',
        variables: {
          manifest_id: manifestId
        }
      })
    })
    .then((response) => response && response.json()),

  GEOCODE_ITEM: (value) => {
    const location = store.getState()[reducer.name].currentLocation;

    return fetch(`${ENDPOINTS.geocode}?q=${value}&lat=${location[0]}&lon=${location[1]}`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${store.getState()[reducer.name].token}`,
          'Accept': '*',
        },
      })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve(response.json());
        }
        return Promise.reject(new Error('Failed to load geocode item'));
      })
      .then((response) => {
        if (response.data)
          return Promise.resolve({
            caption: response.data.name,
            coords: response.data.coords
          });
        return Promise.reject(new Error('No geocode data'));
      })
  },

  GEOCODE_REVERSED: (coords) => (
    fetch(`${ENDPOINTS.geocodeReversed}?lat=${coords[0]}&lon=${coords[1]}`, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to reverse geocode'));
    })
    .then((response) => {
      if (response.data) {
        return Promise.resolve(response.data.name);
      }

      return Promise.reject(new Error('No reverse geocode data'));
    })
  ),

  GEOCODE_ITEM_PHOTON: (value) => (
    fetch(geocodeHintUrl(value), {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to load geocode options'));
    })
    .then((response) => {
      if (response.data && response.data.length)
        return Promise.resolve({
          caption: response.data[0].name,
          coords: response.data[0].coords
        });
      return Promise.reject(new Error('No geocode data'));
    })
  ),

  GEOCODE_OPTIONS: (value) => (
    fetch(geocodeHintUrl(value), {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to load geocode options'));
    })
    .then((response) => {
      let options = [];
      if (response.data && response.data.length)
        options = _.uniqBy(response.data, 'name')
        .slice(0, 8)
        .map(({
          name,
          coords
        }) => ({
          caption: name,
          value: coords,
          key: coords
        }));
      return Promise.resolve(options);
    })
  ),

  getGeocodeOptions: (value) => (
    fetch(geocodeHintUrl(value), {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`,
        'Accept': '*',
      },
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to load geocode options'));
    })
    .then((response) => {
      let options = [];
      if (response.data && response.data.length)
        options = _.uniqBy(response.data, 'name')
        .slice(0, 8)
        .map(({
          name,
          coords
        }) => ({
          caption: name,
          value: coords,
          key: coords
        }));
      return Promise.resolve(options);
    })
    .catch((error) => Promise.reject(new Error(error.message)))
  ),
  CREATE_POLL: (name, spec) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'CREATE_POLL',
      variables: {
        data: {
          name: name,
          spec: spec,
        }
      }
    })
  }),
  UPDATE_POLL: (id, name, spec) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'UPDATE_POLL',
      variables: {
        id: id,
        data: {
          name: name,
          spec: spec,
        }
      }
    })
  }),
  POLL: (name) => fetch(ENDPOINTS.graphql, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${store.getState()[reducer.name].token}`,
        'Accept': '*',
      },
      body: JSON.stringify({
        operationName: 'POLL',
        variables: {
          id: name,
        }
      })
    })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to authorize user'));
    }),
  POST_ANSWER: (id, answer) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'POST_ANSWER',
      variables: {
        id: id,
        answer: answer,
      }
    })
  }),
  POLL_RESULTS: (id) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'POLL_RESULTS',
      variables: {
        id: id,
      }
    })
  })
  .then((response) => {
    if (response.ok) {
      return Promise.resolve(response.json());
    }
    return Promise.reject(new Error('ERROR F'));
  }),


  MANIFEST: (manifestId) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'MANIFEST',
      variables: {
        id: manifestId,
      }
    })
  })
  .then((response) => response && response.json()),
  MANIFEST_LIST: (manifestId, points) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'MANIFEST_LIST',
      variables: {}
    })
  })
  .then((response) => response && response.json()),
  SAVE_MANIFEST: (manifestId) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'SAVE_MANIFEST',
      variables: {
        id: manifestId,
      }
    })
  })
  .then((response) => response && response.json()),
  CREATE_MANIFEST: (manifestName) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'CREATE_MANIFEST',
      variables: {
        data: {
          name: manifestName,
          optimization: "mintime",
          task_type: "optimize",
          runs: 3,
          by_zone: false,
          clusterization: "none",
          distribution: true,
          maxiter: 15000,
          n_days: 1,
          speed_coeff: 1,
          strict_mode: true,
        }
      }
    })
  })
  .then((response) => response && response.json()),
  DELETE_MANIFEST: (manifestId) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'DELETE_MANIFEST',
      variables: {
        id: manifestId,
      }
    })
  })
  .then((response) => response && response.json()),

  MANIFEST_POINTS: (manifestId) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'MANIFEST_POINTS',
      variables: {
        "manifest_id": manifestId,
      }
    })
  })
  .then((response) => response && response.json()),
  CREATE_MANIFEST_POINTS: (manifestId, points) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'CREATE_MANIFEST_POINTS',
      variables: {
        "manifest_id": manifestId,
        "data": points,
      }
    })
  })
  .then((response) => response && response.json()),
  MANIFEST_WAYBILL_POINTS: (manifestId) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'MANIFEST_WAYBILL_POINTS',
      variables: {
        "manifest_id": manifestId,
      }
    })
  })
  .then((response) => response && response.json()),

  MANIFEST_COURIERS: (manifestId) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'MANIFEST_COURIERS',
      variables: {
        "manifest_id": manifestId,
      }
    })
  })
  .then((response) => response && response.json()),
  CREATE_MANIFEST_COURIERS: (manifestId, agents) => fetch(ENDPOINTS.graphql, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${store.getState()[reducer.name].token}`,
      'Accept': '*',
    },
    body: JSON.stringify({
      operationName: 'CREATE_MANIFEST_COURIERS',
      variables: {
        "manifest_id": manifestId,
        "data": agents,
      }
    })
  })
  .then((response) => response && response.json()),
};

export default queries;
